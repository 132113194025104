<template>
  <div>
    <v-container
      class="v-container-fluid"
      fluid
    >
      <v-row>
        <v-col
          cols="12"
          md="12"
        >
          <div>
            <!-- button return -->
            <div class="display-flex align-items-center mt-5">
              <v-icon
                class="icon-return"
                size="15px"
              > mdi-arrow-left </v-icon>
              <span
                @click="$router.push({ name: 'Disputes' })"
                class="text-return text-underline mon-regular"
              >
                {{ texts.disputedetail.buttonReturn }}
              </span>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="12"
        >
          <v-row>
            <v-col
              cols="12"
              md="4"
              sm="12"
            >
              <div class="display-flex align-items-center height-100">
                <p class="text-title mon-bold">
                  {{ texts.disputedetail.textTitle }}
                </p>
              </div>
            </v-col>
            <v-col
              cols="12"
              md="3"
              sm="4"
            >
              <div class="display-flex align-items-center height-100">
                <p class="text-provider mon-regular">
                  {{ texts.disputedetail.textProvider }}
                </p>
                <p
                  class="text-provider-item mon-regular ml-1"
                  @click="redirectVendor"
                >
                  {{ providerName }}
                </p>
              </div>
            </v-col>
            <v-col
              cols="12"
              md="3"
              sm="4"
            >
              <div class="display-flex align-items-center height-100">
                <p class="text-order-date mon-regular">
                  {{ texts.disputedetail.textOrderDate }}
                </p>
                <p class="text-order-date-item mon-regular ml-1">
                  {{ aDisputes.tCreatedAtView }}
                </p>
              </div>
            </v-col>
            <v-col
              cols="12"
              md="2"
              sm="4"
            >
              <div class="d-flex align-center justify-end height-100">
                <div class="d-flex align-center height-100 content-gray">
                  <p class="text-label text-status-name mon-bold ">
                    {{selectLanguage == "sp"? 'Estado': 'Status'}}
                  </p>
                  <v-spacer />
                  <p class="text-label-item text-status-name mon-regular ml-3">
                    {{ statusName }}
                  </p>
                </div>
              </div>
              <!-- <div class="display-flex align-items-center height-100 justify-content-flex-end">
                <p class="text-status-name mon-semibold">{{ statusName }}</p>
              </div> -->
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="12"
        >
          <div class="card-content display-flex align-items-center card-content-block">
            <div class="display-flex align-items-center">
              <p class="text-order-id mon-bold">
                {{ texts.disputedetail.textOrderId }}
              </p>
              <p class="text-order-id-item mon-regular ml-2">{{ folioId }}</p>
            </div>
            <v-spacer />
            <div class="display-flex align-items-center">
              <p class="text-order-total mon-bold">
                {{ texts.disputedetail.textOrderTotal }}
              </p>
              <p class="text-order-total-item mon-regular ml-2">
                ${{ formatMoney(aDisputes.dTotalPrice) }} USD
              </p>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col v-if="isResponsive">
          <div class="content-image-responsive">
            <img :src="imageSelected">
          </div>
          <div class="scrolled-lateral">
            <div
              v-for="(item, index) in aDisputes.aClaimTicketImages"
              :key="index"
            >
              <div
                @click="selectImage(item)"
                class="content-image-responsive-select"
              >
                <img :src="item.oImages.md" />
              </div>
            </div>
          </div>
        </v-col>
        <v-col
          v-else
          cols="12"
          :style="
            countDisputes == 0 ? { display: 'none' } : { display: 'flex' }
          "
          md="6"
        >
          <div>
            <div class="display-flex resize-content">
              <div class="content-fixed-image">
                <div
                  v-for="(item, index) in aDisputes.aClaimTicketImages"
                  :key="index"
                >
                  <div
                    @click="selectImage(item)"
                    class="content-image cursor-pointer"
                  >
                    <img
                      :src="item.oImages.md"
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div class="content-resize-image">
                <zoom-image :imageSelected="imageSelected"></zoom-image>
              </div>
            </div>
          </div>
        </v-col>
        <v-col
          cols="12"
          :md="countDisputes == 0 ? '12' : '6'"
        >
          <div>
            <v-row>
              <v-col
                cols="12"
                md="12"
              >
                <div>
                  <p class="title-section mon-bold">
                    {{ texts.disputedetail.titleSectionOne.textTitle }}
                  </p>
                  <div class="card-content-min">
                    <div class="display-flex align-items-center border-bottom pa-4">
                      <p class="text-title-section-min mon-medium">
                        {{ texts.disputedetail.titleSectionOne.text1 }}
                      </p>
                      <v-spacer />
                      <p class="text-title-section-min-item mon-regular">
                        {{ disputeId }}
                      </p>
                    </div>
                    <div
                      v-if="finishDate != null"
                      class="display-flex align-items-center border-bottom pa-4"
                    >
                      <p class="text-title-section-min mon-medium">
                        {{ texts.disputedetail.titleSectionOne.text2 }}
                      </p>
                      <v-spacer />
                      <p class="text-title-section-min-item mon-regular">
                        {{ finishDate }}
                      </p>
                    </div>
                    <div class="display-flex align-items-center border-bottom pa-4">
                      <p class="text-title-section-min mon-medium">
                        {{ texts.disputedetail.titleSectionOne.text3 }}
                      </p>
                      <v-spacer />
                      <p class="text-title-section-min-item mon-regular">
                        {{ aDisputes.tCreatedAtView }}
                      </p>
                    </div>
                    <div class="display-flex align-items-center border-bottom pa-4">
                      <p class="text-title-section-min mon-medium">
                        {{ texts.disputedetail.titleSectionOne.text4 }}
                      </p>
                      <v-spacer />
                      <p class="text-title-section-min-item mon-regular">
                        {{ aDisputes.sCategoryName }}
                      </p>
                    </div>
                    <div class="display-flex align-items-center border-bottom pa-4">
                      <p class="text-title-section-min mon-medium">
                        {{ texts.disputedetail.titleSectionOne.text5 }}
                      </p>
                      <v-spacer />
                      <p class="text-title-section-min-item mon-regular">
                        {{ aDisputes.sSubcategoryName }}
                      </p>
                    </div>
                    <div class="display-flex align-items-center pa-4">
                      <p class="text-title-section-min mon-medium">
                        {{ texts.disputedetail.titleSectionOne.text6 }}
                      </p>
                      <v-spacer />
                      <p class="text-title-section-min-item mon-regular">
                        {{ aDisputes.sAdminOwnerName == null ?  texts.disputedetail.titleSectionOne.text7:aDisputes.sAdminOwnerName }}
                      </p>
                    </div>
                  </div>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="12"
              >
                <div>
                  <p class="title-section mon-bold">
                    {{ texts.disputedetail.titleSectionTwo }}
                  </p>
                  <div class="card-content-min pa-4">
                    <p class="text-description mon-regular">
                      {{ aDisputes.sDescription }}
                    </p>
                  </div>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="12"
              >
                <div>
                  <p class="title-section mon-bold">
                    {{ texts.disputedetail.titleSectionThree }}
                  </p>
                  <div class="card-content-min pa-4">
                    <p
                      :class="(finishDate !== null)? 'text-description':'text-description-empty'"
                      class=" mon-regular"
                    >
                      {{ (finishDate !== null)? resolutionMessage : texts.disputedetail.sNotResolutionMessage }}
                    </p>
                  </div>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <br />
  </div>
</template>

<script>
export default {
  name: "DisputeDetailContentLayout",
  props: {
    texts: {
      type: Object,
    },
  },
  data() {
    return {
      //VARIABLES
      aDisputes: [],
      providerName: "",
      statusName: "",
      folioId: "",
      imageSelected: "",
      countDisputes: 0,
      resolutionMessage: "",
      finishDate: null,
      disputeId: "",
      isResponsive: false,
    };
  },
  beforeMount() {
    this.getDisputeDetail();
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize: function () {
      if (window.innerWidth < 960) {
        this.isResponsive = true;
      } else {
        this.isResponsive = false;
      }
    },
    redirectVendor: function () {
      this.$router.push({
        name: "VendorDetail",
        params: {
          id: this.aDisputes.oVendor.sEnterpriseId,
        },
      });
    },
    selectImage: function (e) {
      this.imageSelected = e.oImages.lg;
    },
    formatDate: function (date) {
      var new_date = MOMENT(date, "DD/MM/YYYY");
      var format_date = MOMENT(new_date).format("MMM. DD, YYYY.");
      return format_date;
    },
    getDisputeDetail: function () {
      DB.get(
        `${URI}/api/v1/${this.selectLanguage}/claim-tickets/${this.$route.params.id}`,
        {
          headers: {
            Authorization: `Bearer ${this.$store.state.sToken}`,
          },
          params: {},
        }
      )
        .then((response) => {
          let arr = response.data.results;

          this.aDisputes = arr;
          this.countDisputes = arr.aClaimTicketImages;
          this.providerName = arr.oVendor.sName;
          this.statusName = arr.oStatus.sStatusName;
          this.folioId = arr.oSalesOrder.sFolioId;
          this.finishDate = arr.tFinishDate;
          this.resolutionMessage = arr.oStatus.sResolutionMessage;
          this.disputeId = arr.sClaimTicketId.split("-")[4];
          if (arr.aClaimTicketImages.length > 0)
            this.imageSelected = arr.aClaimTicketImages[0].oImages.lg;

          this.$store.commit("refresher", false);
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    formatMoney: function (money) {
      //return number in format money
      return FORMAT_MONEY(money);
    },
  },
  computed: {
    selectLanguage: function () {
      return this.$store.state.language;
    },
  },
  watch: {
    selectLanguage: function () {
      if (this.selectLanguage) {
        this.getDisputeDetail();
      }
    },
  },
};
</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}

.text-description {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #151d27;
  opacity: 1;
  margin-bottom: 0px;
}
.text-description-empty {
  text-align: left;
  font-size: 14px;
  font-style: italic;
  letter-spacing: 0px;
  color: #9aa6b1;
  opacity: 1;
  margin-bottom: 0px;
}

.title-section {
  text-align: left;
  font-size: 18px;
  letter-spacing: 0px;
  color: #151d27;
  opacity: 1;
  margin-bottom: 5px;
}

.border-bottom {
  border-bottom: 1px solid #e0e0e0;
}

.text-title-section-min {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
  margin-bottom: 0px;
}

.text-title-section-min-item {
  text-align: right;
  font-size: 14px;
  letter-spacing: 0px;
  color: #9aa6b1;
  opacity: 1;
  margin-bottom: 0px;
}

.card-content-min {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #e5e5e5;
  border-radius: 9px;
  opacity: 1;
}

.text-order-id {
  text-align: left;
  font-size: 16px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-bottom: 0px;
}

.text-order-id-item {
  text-align: left;
  font-size: 16px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.51;
  margin-bottom: 0px;
}

.text-order-total {
  text-align: left;
  font-size: 16px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-bottom: 0px;
}

.text-order-total-item {
  text-align: left;
  font-size: 18px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-bottom: 0px;
}

.card-content {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #e5e5e5;
  border-radius: 9px;
  opacity: 1;
  padding: 25px 15px;
}

.text-status-name {
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
  margin-bottom: 0px;
}

.text-provider {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-bottom: 0px;
}

.text-provider-item {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #0971fb;
  opacity: 1;
  margin-bottom: 0px;
}

.text-provider-item:hover {
  text-decoration: underline;
  cursor: pointer;
}

.text-order-date {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-bottom: 0px;
}

.text-order-date-item {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.51;
  margin-bottom: 0px;
}

.text-title {
  text-align: left;
  font-size: 25px;
  letter-spacing: 0px;
  color: #151d27;
  opacity: 1;
  margin-bottom: 0px;
}

.icon-return {
  border-radius: 100%;
  width: 27px;
  height: 27px;
  background: transparent linear-gradient(265deg, #ffc556 0%, #fffc81 100%) 0%
    0% no-repeat padding-box;
}

.text-underline:hover {
  text-decoration: underline;
  cursor: pointer;
}

.text-return {
  text-transform: initial;
  margin-left: 5px;
  font-size: 16px;
}

@media (max-width: 600px) {
  .resize-content {
    display: block;
  }
  .content-fixed-image {
    display: flex;
    width: 100% !important;
    align-items: center;
  }
  .content-fixed-image .content-image {
    width: 130px;
    margin: 1px;
    padding: 4px;
  }
  .content-resize-image {
    width: 100% !important;
  }
}

.content-fixed-image {
  width: 125px;
  max-height: 350px;
  overflow: auto;
  padding: 10px;
}

.content-fixed-image .content-image {
  width: 100%;
  height: 100px;
  opacity: 1;
  margin-bottom: 10px;
}

.content-fixed-image .content-image img {
  width: 100%;
  height: auto;
}

@supports (object-fit: cover) {
  .content-fixed-image .content-image img {
    height: 100%;
    object-fit: cover;
    object-position: center center;
    border-radius: 23px;
  }
}

.content-resize-image {
  width: calc(100% - 125px);
  height: 350px;
  padding: 10px;
  border: 1px solid gray;
  border-radius: 23px;
}

.content-resize-image .content-image {
  width: 100%;
  height: 100%;
  opacity: 1;
  margin-bottom: 10px;
}

.content-resize-image .content-image img {
  width: 100%;
  height: auto;
}

@supports (object-fit: cover) {
  .content-resize-image .content-image img {
    height: 100%;
    object-fit: cover;
    object-position: center center;
    border-radius: 23px;
  }
}
.content-gray {
  background: #ebebeb 0% 0% no-repeat padding-box;
  border-radius: 10px;
  padding: 15px;
}

@media (max-width: 960px) {
  .content-gray {
    width: 100%;
  }
  .card-content-block {
    display: block;
  }
}

/* image responsive */
.content-image-responsive {
  width: 100%;
  height: 300px;
  opacity: 1;
  margin-bottom: 10px;
  border: 1px solid gray;
  border-radius: 23px;
}

.content-image-responsive img {
  width: 100%;
  height: auto;
}

@supports (object-fit: contain) {
  .content-image-responsive img {
    height: 100%;
    object-fit: contain;
    object-position: center center;
    border-radius: 23px;
  }
}

.scrolled-lateral {
  overflow-x: auto;
  height: 80px;
  width: 100%;
  display: flex;
  align-items: center;
}

.content-image-responsive-select {
  width: 50px;
  height: 50px;
  border: 1px solid gray;
  border-radius: 10px;
  margin-right: 15px;
}

.content-image-responsive-select img {
  width: 100%;
  height: auto;
}

@supports (object-fit: contain) {
  .content-image-responsive-select img {
    height: 100%;
    object-fit: contain;
    object-position: center center;
    border-radius: 23px;
  }
}
</style>